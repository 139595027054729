import React, { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import './Grid.css';
import {zipHandler, resolveImgPromises} from "./fetcher.js";
import {ImageUrl} from "./imgUrl";
import {AboutModal} from './AboutModal'
import {
    setupAboutLinkClickHandler,
    setupEscapeKeyEventListener,
    setupModalClickListener,
    setupModalClickEscapeListener,
    setupRightArrowListener,
    setupLeftArrowListener,
    setupRightArrowClickListener,
    setupLeftArrowClickListener,
    navbarTogglerActionListener,
    emailSubmitActionListener, collaborateSubmitActionListener
} from "./eventListeners";
import {IWantThisModal} from "./IWantThisModal";
import {Footer} from "./Footer";
import {Collaborate} from "./Collaborate";

function Grid() {
    const [imgPromises, setImgPromises] = useState<ImageUrl[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSingleImage, setIsLoadingSingleImage] = useState<boolean>(false)
    const [reachedEndOfList, setReachedEndOfList] = useState<boolean>(false)
    const [blobsToFilenameMap, setBlobsToFilenameMap] = useState<Map<string, string>>(new Map())
    const [highlightedThumbnail, setHighlightedThumbnail] = useState<ImageUrl | undefined>(undefined)
    const [isTogglingNavbar, setIsTogglingNavbar] = useState<boolean>(false);
    const [touchstartX, setTouchstartX] = useState<number>(0);
    const [isAddingToWaitlist, setIsAddingToWaitlist] = useState<boolean>(false)

    const rightArrowHandlerRef = useRef<(event: KeyboardEvent) => void>(null);
    const rightArrowClickHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const leftArrowHandlerRef = useRef<(event: KeyboardEvent) => void>(null);
    const leftArrowClickHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const modalEscapeKeyHandlerRef = useRef<(event: KeyboardEvent) => void>(null);
    const modalClickExitHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const modalCloseButtonHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const thumbnailClickHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const touchStartHandlerRef = useRef<(event: TouchEvent) => void>(null);
    const touchMoveHandlerRef = useRef<(event: TouchEvent) => void>(null);
    const touchEndHandlerRef = useRef<(event: TouchEvent) => void>(null);
    const aboutLinkClickHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const navbarTogglerActionHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const emailSubmitActionHandlerRef = useRef<(event: MouseEvent) => void>(null);
    const collaborateSubmitActionHandlerRef = useRef<(event: MouseEvent) => void>(null);

    useEffect(() => {
        const doFetch = async (
            currentPage: number,
            setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
            setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
            setReachedEndOfList: React.Dispatch<React.SetStateAction<boolean>>,
            setImgPromises: React.Dispatch<React.SetStateAction<ImageUrl[]>>,
            blobsToFilenameMap: Map<string, string>,
            setBlobsToFilenameMap: React.Dispatch<React.SetStateAction<Map<string, string>>>
        ) => {
            const response = await fetch(`/get_images?page=${currentPage}`);
            if (response.status === 429) {
                await new Promise(r => setTimeout(r, 1000));
                return;
            }
            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
            const newImgPromises: string[] = [];
            if (Object.keys(zip.files).length === 0) {
                setReachedEndOfList(true);
            }
            await zipHandler(zip, newImgPromises, blobsToFilenameMap, setBlobsToFilenameMap);
            await resolveImgPromises(newImgPromises, setImgPromises, setCurrentPage, setIsLoading, imgPromises);
        }

        async function getDoFetchCallback() {
            if (isLoading) {
                return;
            }
            setIsLoading(true);
            await doFetch(currentPage, setCurrentPage, setIsLoading, setReachedEndOfList, setImgPromises, blobsToFilenameMap, setBlobsToFilenameMap);
            const navbar = document.querySelector("#navbar") as HTMLElement;
            navbar.setAttribute('currentTab', 'home');
            setIsLoading(false);
        }

        let options = {
            rootMargin: "0px",
            threshold: 0.1,
        };

        const callback = (entries: any[], observer: IntersectionObserver) => {
            for (let entry of entries) {
                if (entry.isIntersecting && !isLoading && !reachedEndOfList) {
                    setIsLoading(true)
                    const loadingSpinner = document.getElementById("loading-spinner")
                    if (loadingSpinner) {
                        loadingSpinner.style.display = "inline-block";
                    }
                    getDoFetchCallback()
                        .then(() => observer.unobserve(entry.target))
                }
            }
        }
        const observer = new IntersectionObserver(callback, options)
        const targetEl = document.querySelector('#scrollArea')
        if (targetEl) {
            observer.observe(targetEl);
        }

        setupEscapeKeyEventListener(modalEscapeKeyHandlerRef);
        setupModalClickEscapeListener(modalClickExitHandlerRef);
        setupRightArrowListener(isLoading, setIsLoading, isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap, imgPromises, rightArrowHandlerRef, getDoFetchCallback);
        setupRightArrowClickListener(isLoading, setIsLoading, isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap, imgPromises, rightArrowClickHandlerRef, getDoFetchCallback);
        setupLeftArrowListener(isLoading, isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap, imgPromises, leftArrowHandlerRef);
        setupLeftArrowClickListener(isLoading, isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap, imgPromises, leftArrowClickHandlerRef);
        setupModalClickListener(isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap, isLoading, thumbnailClickHandlerRef);
        setupAboutLinkClickHandler(isLoading, isLoadingSingleImage, aboutLinkClickHandlerRef, observer);
        navbarTogglerActionListener(isTogglingNavbar, setIsTogglingNavbar, navbarTogglerActionHandlerRef);
        emailSubmitActionListener(isAddingToWaitlist, setIsAddingToWaitlist, emailSubmitActionHandlerRef);
        collaborateSubmitActionListener(collaborateSubmitActionHandlerRef);

        const rightArrowHandler = rightArrowHandlerRef.current;
        const rightArrowClickHandler = rightArrowClickHandlerRef.current;
        const leftArrowHandler = leftArrowHandlerRef.current;
        const leftArrowClickHandler = leftArrowClickHandlerRef.current;
        const modalEscapeKeyHandler = modalEscapeKeyHandlerRef.current;
        const modalClickExitHandler = modalClickExitHandlerRef.current;
        const modalCloseButtonHandler = modalCloseButtonHandlerRef.current;
        const thumbnailClickHandler = thumbnailClickHandlerRef.current;
        let touchStartHandler = touchStartHandlerRef.current;
        let touchMoveHandler = touchMoveHandlerRef.current;
        let touchEndHandler = touchEndHandlerRef.current;
        let aboutLinkClickHandler = aboutLinkClickHandlerRef.current;
        let navbarTogglerActionHandler = navbarTogglerActionHandlerRef.current;
        let emailSubmitActionHandler = emailSubmitActionHandlerRef.current;
        const collaborateSubmitActionHandler = collaborateSubmitActionHandlerRef.current;

        if (rightArrowHandler) {
            window.addEventListener('keydown', rightArrowHandler);
        }
        if (rightArrowClickHandler) {
            window.addEventListener('click', rightArrowClickHandler);
        }
        if (leftArrowHandler) {
            window.addEventListener('keydown', leftArrowHandler);
        }
        if (leftArrowClickHandler) {
            window.addEventListener('click', leftArrowClickHandler);
        }
        if (modalEscapeKeyHandler) {
            window.addEventListener('keydown', modalEscapeKeyHandler);
        }
        if (modalClickExitHandler) {
            window.addEventListener('click', modalClickExitHandler);
        }
        if (thumbnailClickHandler) {
            window.addEventListener('click', thumbnailClickHandler);
        }
        if (touchStartHandler) {
            window.addEventListener('touchstart', touchStartHandler);
        }
        if (touchMoveHandler) {
            window.addEventListener('touchmove', touchMoveHandler);
        }
        if (touchEndHandler) {
            window.addEventListener('touchend', touchEndHandler);
        }
        if (aboutLinkClickHandler) {
            window.addEventListener('click', aboutLinkClickHandler);
        }
        if (navbarTogglerActionHandler) {
            window.addEventListener('click', navbarTogglerActionHandler);
        }
        if (emailSubmitActionHandler) {
            window.addEventListener('click', emailSubmitActionHandler);
        }
        if (collaborateSubmitActionHandler) {
            window.addEventListener('click', collaborateSubmitActionHandler)
        }

        return () => {
            if (leftArrowHandler) {
                window.removeEventListener('keydown', leftArrowHandler);
            }
            if (leftArrowClickHandler) {
                window.removeEventListener('click', leftArrowClickHandler);
            }
            if (rightArrowHandler) {
                window.removeEventListener('keydown', rightArrowHandler);
            }
            if (rightArrowClickHandler) {
                window.removeEventListener('click', rightArrowClickHandler);
            }
            if (modalEscapeKeyHandler) {
                window.removeEventListener('keydown', modalEscapeKeyHandler);
            }
            if (modalCloseButtonHandler) {
                window.removeEventListener('click', modalCloseButtonHandler);
            }
            if (thumbnailClickHandler) {
                window.removeEventListener('click', thumbnailClickHandler);
            }
            if (touchStartHandler) {
                window.removeEventListener('touchend', touchStartHandler);
            }
            if (touchMoveHandler) {
                window.removeEventListener('touchmove', touchMoveHandler);
            }
            if (touchEndHandler) {
                window.removeEventListener('touchend', touchEndHandler);
            }
            if (aboutLinkClickHandler) {
                window.removeEventListener('click', aboutLinkClickHandler);
            }
            if (navbarTogglerActionHandler) {
                window.removeEventListener('click', navbarTogglerActionHandler)
            }
            if (emailSubmitActionHandler) {
                window.removeEventListener('click', emailSubmitActionHandler);
            }
            if (collaborateSubmitActionHandler) {
                window.removeEventListener('click', collaborateSubmitActionHandler)
            }

            observer.disconnect();
        };
    }, [
        currentPage,
        setCurrentPage,
        isLoading,
        setIsLoading,
        reachedEndOfList,
        imgPromises,
        setImgPromises,
        isLoadingSingleImage,
        blobsToFilenameMap,
        highlightedThumbnail,
        setHighlightedThumbnail,
        setIsLoadingSingleImage,
        rightArrowHandlerRef,
        touchstartX,
        setTouchstartX,
        isTogglingNavbar,
        setIsTogglingNavbar,
        isAddingToWaitlist,
        setIsAddingToWaitlist
    ]);

    return (
        <div>
            <div id="grid">
                <div className="container-fluid title-container">
                    <div>
                        <img src="emeraldcaressdotlogo.png" className="App-logo" alt=""/>
                    </div>
                    <h1 id="emerald-caress">
                        Emerald Caress
                    </h1>
                </div>

                <div className="container text-center">
                    <nav id="navbar" className="navbar navbar-expand-lg dark bg-dark">
                        <button id="navbarToggler" className="navbar-toggler" type="button" aria-expanded="false">
                            <span id="navbarTogglerIcon" className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                                <li className="nav-item mx-2">
                                    <div id='home' className="nav-link">Home</div>
                                </li>
                                <li className="nav-item mx-2">
                                    <div id="about" className="nav-link">About</div>
                                </li>
                                <li className="nav-item mx-2">
                                    <div id="collaborate" className="nav-link">Collaborate</div>
                                </li>
                                <li className="nav-item mx-2">
                                    <div id='iWantThis' className="nav-link">I Want This!</div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div id="container" className="container text-center thumbnail">
                    {/*{imgPromises.map(url => (*/}
                    {/*    <img alt="alt" src={url.url} key={url.url} className="thumbnail-img rounded img-fluid mb-2"/>*/}
                    {/*))}*/}
                    <span id="scroll-area-buffer"/>
                    <div className="d-flex justify-content-center">
                        <div id="loading-spinner" className="spinner-border text-secondary"
                             style={{width: "2rem", height: "2rem"}} role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                    <div id="scrollArea" style={{height: "1px"}}></div>
                    {/*<div style={{paddingTop: "5rem"}}/>*/}
                </div>

                <div id="homeFooter">
                    <br/>
                    <Footer/>
                </div>
                <div className="container">
                    <AboutModal/>
                    <IWantThisModal/>
                    <Collaborate />
                </div>
            </div>
            <div id="originalImageModal" className="modal justify-content-center shadow-lg">
                <button type="button" id="originalImageModalClose" className="modal-close" aria-label="Close">
                    X
                    {/*<span aria-hidden="true">&times;</span>*/}
                </button>
                <img id="originalImage" className="shadow-lg" src=""/>
                <div className="imageModalArrowButtons">
                    <button type="button" id="imageModalLeftArrow" className="caret-left-fill">
                        {"<"}
                    </button>
                    <button type="button" id="imageModalRightArrow" className="caret-right-fill">
                        {">"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Grid;