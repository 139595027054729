import React from "react";
import {fetchSingleImage} from "./fetcher.js";
import {ImageUrl} from "./imgUrl";
import './Grid.css';
import {debounce, result} from 'lodash';

export const setupIntersectionObserver = function (
    isLoading: boolean,
    reachedEndOfList: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    getDoFetchCallback: () => Promise<void>,
) {

}

export const setupEscapeKeyEventListener = function (
    modalEscapeKeyHandlerRef: React.MutableRefObject<((event: KeyboardEvent) => void) | null>
) {
    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;
    if (originalImageModal?.getAttribute("escapeListenerDefined") === "true") {
        return;
    }
    modalEscapeKeyHandlerRef.current = function (event: KeyboardEvent) {
        if (event.key === 'Escape' || event.keyCode === 27) {
            // Close the modal if it's open
            if (originalImageModal.style.display !== 'none') {
                originalImageModal.style.display = 'none';
            }
        }
    };
    document.removeEventListener('keydown', modalEscapeKeyHandlerRef.current);
    document.addEventListener('keydown', modalEscapeKeyHandlerRef.current);
}

function generateImgClickEventHandler(
    imgElement: HTMLImageElement,
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>
) {
    return function () {
        if (isLoadingSingleImage) {
            return
        }
        try {
            setIsLoadingSingleImage(true);
            return fetchSingleImage(imgElement.src, blobsToFilenameMap)
                .then(() => {
                    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;
                    const originalImage = document.getElementById('originalImage') as HTMLElement;
                    if (window.innerWidth < 500) {
                        originalImageModal.style.left = '0px';
                    }
                    originalImage.style.left = originalImageModal.style.left;
                    // originalImageModal.style.display = 'block';
                })
                .then(() => setIsLoadingSingleImage(false));
        } catch (error) {
            console.error('Error fetching original image:', error);
        }
    }
}

export const setupModalClickListener = function (
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    isLoading: boolean,
    thumbnailClickHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>
) {
    const thumbnailContainer = document.querySelector('.thumbnail');
    if (!thumbnailContainer || isLoadingSingleImage || isLoading) {
        return
    }
    const prevClassList = thumbnailContainer.classList.toString();

    thumbnailClickHandlerRef.current = (event: MouseEvent) => {
        const target = event.target as HTMLElement

        if (target?.tagName === 'IMG' && target?.classList.contains('thumbnail-img')) {
            document.body.classList.add('no-scroll');

            const img = target as HTMLImageElement;
            const handler = generateImgClickEventHandler(img, isLoadingSingleImage, setIsLoadingSingleImage, blobsToFilenameMap);
            return handler()
                ?.then(() => {
                    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;
                    originalImageModal.setAttribute("thumbnail-url", img.src);
                })
                ?.catch(() => {
                    console.warn('Error fetching original image')
                    thumbnailContainer.className = prevClassList;
                });
        }
    }
    window.removeEventListener('click', thumbnailClickHandlerRef.current);
    window.addEventListener('click', thumbnailClickHandlerRef.current);
}

export const setupModalClickEscapeListener = function (
    modalClickExitHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>
) {
    const originalImageModal = document.getElementById('originalImageModal');
    if (originalImageModal?.getAttribute("escapeListenerDefined") === "true") {
        return;
    }
    originalImageModal?.setAttribute("escapeListenerDefined", "true");
    if (originalImageModal) {
        modalClickExitHandlerRef.current = (event: MouseEvent) => {
            if (event.target === originalImageModal) {
                originalImageModal.style.display = 'none';
                document.body.classList.remove('no-scroll');
            }
        }

        originalImageModal.removeEventListener('click', modalClickExitHandlerRef.current);
        originalImageModal.addEventListener('click', modalClickExitHandlerRef.current);
    }
}

export const setupRightArrowListener = function (
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    imgUrlObjects: ImageUrl[],
    rightArrowHandlerRef: React.MutableRefObject<((event: KeyboardEvent) => void) | null>,
    doFetchCallback: () => Promise<void>
) {
    rightArrowHandlerRef.current = (event: KeyboardEvent) => {
        const originalImage = document.getElementById('originalImage') as HTMLImageElement;

        if (event.key === 'ArrowRight' || event.keyCode === 39) {
            doRightArrowAction(
                isLoading,
                setIsLoading,
                isLoadingSingleImage,
                imgUrlObjects,
                setIsLoadingSingleImage,
                blobsToFilenameMap,
                doFetchCallback,
            )
        }
        originalImage.style.animation = 'none';
    };
    window.removeEventListener('keydown', rightArrowHandlerRef.current);

    window.addEventListener('keydown', rightArrowHandlerRef.current);
}

export const setupRightArrowClickListener = function (
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    imgUrlObjects: ImageUrl[],
    rightArrowClickHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>,
    doFetchCallback: () => Promise<void>
) {
    rightArrowClickHandlerRef.current = (event: MouseEvent) => {
        const originalImage = document.getElementById('originalImage') as HTMLImageElement;

        const target = event.target as HTMLElement

        if (target?.id === 'imageModalRightArrow') {
            target.style.color = 'orange';
            target.style.transform = 'scale(2, 2)';
            doRightArrowAction(
                isLoading,
                setIsLoading,
                isLoadingSingleImage,
                imgUrlObjects,
                setIsLoadingSingleImage,
                blobsToFilenameMap,
                doFetchCallback,
            )?.then(() => {
                setTimeout(() => {
                    target.style.color = 'white';
                    target.style.transform = 'scale(3, 3)';
                }, 100);
            }).catch(
                () => {
                    target.style.color = 'white';
                    target.style.transform = 'scale(3, 3)';
                }
            );
        }
        originalImage.style.animation = 'none';
    };
    window.removeEventListener('click', rightArrowClickHandlerRef.current);

    window.addEventListener('click', rightArrowClickHandlerRef.current);
}

export const setupLeftArrowListener = function (
    isLoading: boolean,
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    imgUrlObjects: ImageUrl[],
    leftArrowHandlerRef: React.MutableRefObject<((event: KeyboardEvent) => void) | null>
) {
    leftArrowHandlerRef.current = (event: KeyboardEvent) => {
        if (event.key === 'ArrowLeft' || event.keyCode === 37) {
            doLeftArrowAction(
                isLoading,
                isLoadingSingleImage,
                imgUrlObjects,
                setIsLoadingSingleImage,
                blobsToFilenameMap
            );
        }
    };
    window.removeEventListener('keydown', leftArrowHandlerRef.current);

    window.addEventListener('keydown', leftArrowHandlerRef.current);
}

export const setupLeftArrowClickListener = function (
    isLoading: boolean,
    isLoadingSingleImage: boolean,
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    imgUrlObjects: ImageUrl[],
    leftArrowClickHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>
) {
    leftArrowClickHandlerRef.current = (event: MouseEvent) => {
        const target = event.target as HTMLElement

        if (target?.id === 'imageModalLeftArrow') {
            target.style.color = 'orange';
            target.style.transform = 'scale(2, 2)';
            doLeftArrowAction(
                isLoading,
                isLoadingSingleImage,
                imgUrlObjects,
                setIsLoadingSingleImage,
                blobsToFilenameMap
            )?.then(() => {
                setTimeout(() => {
                    target.style.color = 'white';
                    target.style.transform = 'scale(3, 3)';
                }, 100);
            }).catch(
                () => {
                    target.style.color = 'white';
                    target.style.transform = 'scale(3, 3)';
                }
            );
        }
    };
    window.removeEventListener('click', leftArrowClickHandlerRef.current);

    window.addEventListener('click', leftArrowClickHandlerRef.current);
}

const doRightArrowAction = function (
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isLoadingSingleImage: boolean,
    imgUrlObjects: ImageUrl[],
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>,
    getDoFetchCallback: () => Promise<void>,
) {
    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;
    if (!originalImageModal || originalImageModal.style.display === 'none' || isLoading || isLoadingSingleImage) {
        return
    }
    const _highlightedThumbnail = imgUrlObjects.find(imgUrl => imgUrl.url === originalImageModal.getAttribute("thumbnail-url"));
    if (_highlightedThumbnail?.next) {
        setIsLoadingSingleImage(true)
        return fetchSingleImage(_highlightedThumbnail.next.url, blobsToFilenameMap)
            .then(() => {
                if (_highlightedThumbnail?.next) {
                    originalImageModal.setAttribute("thumbnail-url", _highlightedThumbnail.next.url);
                }
                setIsLoadingSingleImage(false);
            })
    } else {
            setIsLoading(true)
            const loadingSpinner = document.getElementById("loading-spinner")
            if (loadingSpinner) {
                loadingSpinner.style.display = "inline-block";
            }
            return getDoFetchCallback()
                .then(() => new Promise(() => setIsLoadingSingleImage(false)));
    }
}

const doLeftArrowAction = function (
    isLoading: boolean,
    isLoadingSingleImage: boolean,
    imgUrlObjects: ImageUrl[],
    setIsLoadingSingleImage: React.Dispatch<React.SetStateAction<boolean>>,
    blobsToFilenameMap: Map<string, string>
) {
    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;
    if (!originalImageModal || originalImageModal.style.display === 'none' || isLoading || isLoadingSingleImage) {
        return
    }
    const _highlightedThumbnail = imgUrlObjects.find(imgUrl => imgUrl.url === originalImageModal.getAttribute("thumbnail-url"));
    if (_highlightedThumbnail?.prev) {
        setIsLoadingSingleImage(true)
        return fetchSingleImage(_highlightedThumbnail.prev.url, blobsToFilenameMap)
            .then(() => {
                if (_highlightedThumbnail?.prev) {
                    originalImageModal.setAttribute("thumbnail-url", _highlightedThumbnail.prev.url);
                }
                setIsLoadingSingleImage(false);
            })
    } else {
        setIsLoadingSingleImage(false);
        return new Promise((resolve) => resolve("No prev image"));
    }
}

export const setupAboutLinkClickHandler = function(
    isLoading: boolean,
    isLoadingSingleImage: boolean,
    aboutLinkClickHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>,
    intersectionObserver: IntersectionObserver,
) {
    aboutLinkClickHandlerRef.current = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        doHandleAboutLinkClick(isLoading, isLoadingSingleImage, target);
    }

    window.removeEventListener('click', aboutLinkClickHandlerRef.current);
    window.addEventListener('click', aboutLinkClickHandlerRef.current);
}

export const doHandleAboutLinkClick = function(
    isLoading: boolean,
    isLoadingSingleImage: boolean,
    target: HTMLElement | null
) {
    if (!target || isLoading || isLoadingSingleImage) {
        return;
    }
    const navbar = document.querySelector("#navbar") as HTMLElement;
    const currentNavbarTab = navbar.getAttribute('currentTab')
    if (
        target?.id === null
        || !currentNavbarTab
        || currentNavbarTab === target?.id
        || !target.classList.contains('nav-link')
    ) {
        document.querySelector("#navbarTogglerDemo02")?.classList.remove("show");
        return;
    }

    const mappings : Record<string, string> = {
        about: 'aboutModal',
        home: 'container',
        iWantThis: 'iWantThisModal',
        collaborate: 'collaborateModal',
    }
    const displayMappings : Record<string, string> = {
        about: 'block',
        home: 'block',
        iWantThis: 'block',
        collaborate: 'block'
    }
    let currentModalSelector = mappings[currentNavbarTab];
    const currentModal = document.querySelector(`#${currentModalSelector}`) as HTMLElement;
    const targetModal = document.querySelector(`#${mappings[target.id]}`) as HTMLElement;
    const homeFooter = document.querySelector("#homeFooter") as HTMLElement;

    targetModal.style.display = displayMappings[target.id];
    if (target.id !== 'home') {
        const top = navbar.getBoundingClientRect().bottom;
        // targetModal.style.left = `${left.toString()}px`;
        targetModal.style.top = `${top.toString()}px`;
        homeFooter.style.display = 'none'
    } else {
        homeFooter.style.display = 'block'
    }
    currentModal.style.display = 'none'
    navbar.setAttribute('currentTab', target.id);
    document.querySelector("#navbarTogglerDemo02")?.classList.remove("show");
}
export const navbarTogglerActionListener = function(
    isTogglingNavbar: boolean,
    setIsTogglingNavbar: React.Dispatch<React.SetStateAction<boolean>>,
    navbarTogglerActionHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>,
) {
    navbarTogglerActionHandlerRef.current = (event: MouseEvent) => {
        const navbarToggler = document.querySelector("#navbarTogglerDemo02") as HTMLElement;
        const target = event.target as HTMLElement;

        if (target.id !== "navbarTogglerIcon" || isTogglingNavbar) {
            return;
        }
        setIsTogglingNavbar(true);

        const navbarExpanded = navbarToggler?.classList.contains("show")
        const mappings : Record<string, string> = {
            about: 'aboutModal',
            home: 'container',
            iWantThis: 'iWantThisModal',
            collaborate: 'collaborateModal',
        }
        const navbar = document.querySelector("#navbar") as HTMLElement;
        const currentNavbarTab = navbar.getAttribute('currentTab')
        if (!currentNavbarTab) {
            return;
        }
        const navbarButton = document.querySelector("#navbarToggler") as HTMLElement;
        let currentModalSelector = mappings[currentNavbarTab];
        const currentModal = document.querySelector(`#${currentModalSelector}`) as HTMLElement;

        if (navbarExpanded) {
            navbarToggler?.classList.remove("show")
        } else {
            navbarToggler?.classList.add("show")
            currentModal.style.position = 'relative !important';
            currentModal.style.top = `-${navbarButton.getBoundingClientRect().bottom}px !important`;
            navbarToggler.style.zIndex = '1000';
        }
        setIsTogglingNavbar(false);
    }

    window.addEventListener('click', navbarTogglerActionHandlerRef.current);
    window.removeEventListener('click', navbarTogglerActionHandlerRef.current);
}

function updateWaitlist(
    setIsAddingToWaitlist: React.Dispatch<React.SetStateAction<boolean>>,
) {
    const iWantThisEmailInputEmail = document.querySelector("#iWantThisEmailInputEmail") as HTMLInputElement;
    fetch("/waitlist", {
        method: "POST",
        body: JSON.stringify({
            email: iWantThisEmailInputEmail.value
        }),
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        if (response.ok) {
            const successAlert = document.querySelector("#waitlistAddSuccessAlert");
            successAlert?.classList.remove("invisible");
        } else {
            const failureAlert = document.querySelector("#waitlistAddFailureAlert");
            if (!failureAlert) {
                return;
            }
            if (response.status === 429) {
                failureAlert.classList.remove("invisible");
                failureAlert.textContent = "We're experiencing a large volume of requests. Please try again in a few seconds."
                return;
            }
            response.json()
                .then((body => {
                    failureAlert.classList.remove("invisible");
                    failureAlert.textContent = body.err
                }));
        }
        setIsAddingToWaitlist(false)
    }).catch(response => {
        console.error(response)
    });
}

function isValidEmail(emailSelector: string) {
    const selectorElement = document.querySelector(emailSelector) as HTMLInputElement;

    const match = selectorElement.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!match || match.length === 0) {
        document.querySelector(emailSelector)?.classList.add("is-invalid")
        return false;
    }

    return true;
}

export const emailSubmitActionListener = function(
    isAddingToWaitlist: boolean,
    setIsAddingToWaitlist: React.Dispatch<React.SetStateAction<boolean>>,
    emailSubmitActionHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>,
) {
    emailSubmitActionHandlerRef.current = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.id !== "notifyMeButton") {
            return;
        }

        if (!isValidEmail("#iWantThisEmailInputEmail")) {
            const successAlert = document.querySelector("#waitlistAddSuccessAlert");
            const failureAlert = document.querySelector("#waitlistAddFailureAlert");
            successAlert?.classList.add('invisible');
            failureAlert?.classList.add('invisible');
            document.querySelector("#iWantThisEmailInputEmail")?.classList.add("is-invalid")
            return;
        }
        if (isAddingToWaitlist) {
            return;
        }
        setIsAddingToWaitlist(true);
        updateWaitlist(setIsAddingToWaitlist);
    }

    window.removeEventListener('click', emailSubmitActionHandlerRef.current);
    window.addEventListener('click', emailSubmitActionHandlerRef.current);
}

async function updateCollaborateList() {
    const collaborateEmailInputEmail = document.querySelector("#collaborateEmailInputEmail") as HTMLInputElement;
    const formControlTextarea = document.querySelector("#formControlTextarea") as HTMLInputElement;
    const request = JSON.stringify({
        email: collaborateEmailInputEmail.value,
        comments: formControlTextarea.value
    })
    fetch("/collaborate", {
        method: "POST",
        body: request,
        headers: {'Content-Type': 'application/json'},
    }).then(response => {
        if (response.ok) {
            const successAlert = document.querySelector("#collaborateAddSuccessAlert");
            successAlert?.classList.remove("invisible");
        } else {
            const failureAlert = document.querySelector("#collaborateAddFailureAlert");
            if (!failureAlert) {
                return;
            }
            if (response.status === 429) {
                failureAlert.classList.remove("invisible");
                failureAlert.textContent = "We're experiencing a large volume of requests. Please try again in a few seconds."
                return;
            }
            if (response.status === 422) {
                failureAlert.classList.remove("invisible");
                failureAlert.textContent = "Something went wrong. Please try again in a few seconds."
                return;
            }
            response.json()
                .then((body => {
                    failureAlert.classList.remove("invisible");
                    failureAlert.textContent = body.err
                }));
        }
    }).catch(response => {
        console.error(response)
    });
}

export const collaborateSubmitActionListener = function(
    collaborateSubmitActionHandlerRef: React.MutableRefObject<((event: MouseEvent) => void) | null>,
) {
    collaborateSubmitActionHandlerRef.current = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (target.id !== "collaborateButton") {
            return;
        }

        if (!isValidEmail("#collaborateEmailInputEmail")) {
            const successAlert = document.querySelector("#collaborateAddSuccessAlert");
            const failureAlert = document.querySelector("#collaborateAddFailureAlert");
            successAlert?.classList.add('invisible');
            failureAlert?.classList.add('invisible');
            document.querySelector("#collaborateEmailInputEmail")?.classList.add("is-invalid")
            return;
        }
        updateCollaborateList()
    }

    window.removeEventListener('click', collaborateSubmitActionHandlerRef.current);
    window.addEventListener('click', collaborateSubmitActionHandlerRef.current);
}
