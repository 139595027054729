import React from "react";
import './Grid.css'
import {Footer} from "./Footer";

export function IWantThisModal() {
    return (
        <div id="iWantThisModal" className="container">
            <br/>
            <h2 className="heading2">Join the waitlist!</h2>
            <br/>
            <p className="i-want-this-text">
                Although originally it was not my intention to develop Emerald Caress as an app,
                I have been overwhelmed by the inquiries from other artists requesting a website
                like this for a more personal and intimate flavour.
            </p>
            <p className="i-want-this-text">
                Currently, I am accepting artists onto a waitlist as I collaborate with a
                dedicated team of other FAANG engineers and designers to create an exceptional
                experience for you to host <i><b>your art</b></i>! Please enter your email below and I'll get back to
                you when we're ready, soon!
                <br/>
            </p>

            <div id="waitlistAddSuccessAlert" className="alert alert-success invisible" role="alert">
                You were successfully added to the wait list!
            </div>
            <div id="waitlistAddFailureAlert" className="alert alert-danger invisible" role="alert">
            </div>

            <form id="iWantThisModalSubmitForm">
                <div id="iWantThisModalSubmit" className="form-group">
                    <input type="email" className="form-control" id="iWantThisEmailInputEmail" aria-describedby="emailHelp"
                           placeholder="Enter email"/>
                </div>
                <div id="invalidEmailFeedback" className="invalid-feedback is-invalid">
                    Please provide a valid email
                </div>
                <button id="notifyMeButton" type="button" className="btn btn-primary email-submit-button">Notify me!
                </button>
                <br/>
                <small id="emailHelp" className="form-text text-muted ">We'll never share your email with anyone
                    else.</small>
            </form>
            <br/>
            <br/>
            <br/>

            <Footer/>
        </div>
    )
}