import React from "react";
import './Grid.css'
import {Footer} from "./Footer";

export function Collaborate() {
    return (
        <div id="collaborateModal" className="container">
            <br/>
            <h2 className="heading2">Collaborate</h2>
            <br/>
            <p className="collaborate-text">
                I'm keen to collaborate with anyone passionate about photography and modeling. If you're interested
                in being part of creative and fun photoshoots, <i><b>I'd love to hear from you.</b></i> I promise to make it fun! 🤞
            </p>

            <p className="collaborate-text">
                Drop your email and a bit about yourself below, along with how I can reach you. If you've got an
                Instagram handle, throw that in too, so I can get back to you soon!
            </p>

            <form id="collaborateSubmitForm">
                <div id="collaborateAddSuccessAlert" className="alert alert-success invisible" role="alert">
                    Your request has been submitted!
                </div>
                <div id="collaborateAddFailureAlert" className="alert alert-danger invisible" role="alert">
                </div>
                {/*<div id="collaborateModalSubmit" className="form-group">*/}
                    <input type="email" className="form-control" id="collaborateEmailInputEmail"
                           aria-describedby="emailHelp"
                           placeholder="Enter email"/>
                {/*</div>*/}
                <div id="invalidEmailFeedback" className="invalid-feedback is-invalid">
                    Please provide a valid email
                </div>
                <br/>
                {/*<div className="form-group">*/}
                <textarea className="form-control" id="formControlTextarea"
                          placeholder="About me ...">
                </textarea>
                {/*</div>*/}
                <br/>
                <button id="collaborateButton" type="button" className="btn btn-primary email-submit-button">
                    Collaborate!
                </button>
                <br/>
                <small id="emailHelp" className="form-text text-muted ">We'll never share your email with anyone
                    else.</small>
            </form>
            <br/>
            <br/>
            <br/>

            <Footer/>
        </div>
    )
}