import JSZip from "jszip";
import React from "react";
import {ImageUrl, urlsToImageUrls} from "./imgUrl";
import {
    organizeImagesIntoRows,
    transformToDoublyLinkedList,
    transformImageHeights,
    transformImageWidths
} from "./thumbnailResolver";
import {debounce} from "lodash";


export const resolveImgPromises = async function (
    newImgPromises: string[],
    setImgPromises: React.Dispatch<React.SetStateAction<ImageUrl[]>>,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    imgPromises: ImageUrl[]
) {
    const imgUrls: string[] = await Promise.all(newImgPromises);
    let newImgUrlObjs: ImageUrl[] = [];
    setImgPromises((prevImagUrls: ImageUrl[]) => {
        const imgUrlObjects = urlsToImageUrls(imgUrls);
        transformToDoublyLinkedList(prevImagUrls, imgUrlObjects);
        organizeImagesIntoRows(imgUrlObjects);

        newImgUrlObjs = [...prevImagUrls, ...imgUrlObjects]

        return newImgUrlObjs;
    });

    // perform thumbnail transformations after the DOM has been updated
    setTimeout(() => {
        transformImageHeights();
        transformImageWidths();
    }, 25);

    const loadingSpinner = document.getElementById("loading-spinner");
    if (loadingSpinner) {
        loadingSpinner.style.display = "none";
    }
    setCurrentPage(prevState => prevState + 1);
    setIsLoading(false);
    return new Promise(resolve => resolve(newImgUrlObjs));
}


export const zipHandler = async function(
    zip: JSZip,
    newImgPromises: any[],
    blobsToFilenameMap: Map<string, string>,
    setBlobsToFilenameMap: React.Dispatch<React.SetStateAction<Map<string, string>>>
) {
    zip.forEach((_, file) => {
        if (!file.dir) {
            newImgPromises.push(
                file.async('blob').then(blob => {
                    let urlString = URL.createObjectURL(blob);
                    const splits = urlString.split("/");
                    if (!splits) {
                        return
                    }
                    let originalFilename = splits.slice(0, splits.length - 1).join("/") + '/' + file.name;
                    const filenameURL = URL.createObjectURL(blob);
                    setBlobsToFilenameMap(new Map(blobsToFilenameMap.set(filenameURL, originalFilename)));
                    return filenameURL;
                })
            );
        }
    });
}

export async function fetchSingleImage(
    imgUrl: string,
    blobsToFilenameMap: Map<string, string>
) {
    const originalImage = document.getElementById('originalImage') as HTMLImageElement;
    const originalImageModal = document.getElementById('originalImageModal') as HTMLElement;

    let imgPromise: Promise<string> | null = null;

    const imageId = blobsToFilenameMap.get(imgUrl)?.split("/").slice(-1)[0];
    if (!imageId) {
        return
    }
    let wantOriginalImage = false;
    if (window.innerWidth > 800) {
        wantOriginalImage = true;
    }
    const response = await fetch(`/get_image?id=${imageId}&wantoriginal=${wantOriginalImage}`);
    if (response.status === 429) {
        const debounced: any = debounce(() => {
        }, 2000);
        return debounced();
    }
    const blob = await response.blob();
    return JSZip
        .loadAsync(blob)
        .then(zip => {
            zip.forEach((_, file) => {
                if (!file.dir) {
                    imgPromise = file.async('blob').then(blob_1 => {
                        return URL.createObjectURL(blob_1);
                    });
                }
            });

            return imgPromise
                ?.then(imgUrl_1 => {
                    if (imgUrl_1) {
                        originalImage.src = imgUrl_1;
                        originalImageModal.style.display = 'inline-grid';
                    }
                });
        });
}
