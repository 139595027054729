import React from "react";

export function Footer() {
    return (
        <div id="container-footer">
            <p className="copyright-note">
                © 2024 Emerald Caress. Images not meant for copying or
                redistribution.
            </p>
        </div>
    );
}