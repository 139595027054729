import { ImageUrl } from './imgUrl';

export const transformToDoublyLinkedList = function(prevImagUrls: ImageUrl[], imgUrlObjects: ImageUrl[]) {
    if (prevImagUrls.length > 0 && imgUrlObjects.length > 0) {
        prevImagUrls[prevImagUrls.length - 1].next = imgUrlObjects[0];
        imgUrlObjects[0].prev = prevImagUrls[prevImagUrls.length - 1];
    }

    for (let i = 1; i < imgUrlObjects.length; i++) {
        imgUrlObjects[i - 1].next = imgUrlObjects[i];
        imgUrlObjects[i].prev = imgUrlObjects[i - 1];
    }
};

function createNewCurrentRow() {
    let currentRow = document.createElement('div');
    currentRow.className = 'thumbnail-row row align-items-start justify-content-center';
    return currentRow;
}

export const organizeImagesIntoRows = function(
    imgUrlObjects: ImageUrl[]
) {
    let currentRow: HTMLDivElement =
        document.querySelectorAll<HTMLImageElement>('.thumbnail-row:last-child')[0];
    let currentRowCommitted = false;
    if (currentRow) {
        currentRowCommitted = true;
    }
    for (let i = 0; i < imgUrlObjects.length; i ++) {
        if (!currentRow) {
            currentRow = createNewCurrentRow();
            currentRowCommitted = false
        } else if (currentRow.querySelectorAll('.thumbnail-img').length === 3) {
            if (!currentRowCommitted) {
                document.getElementById('container')?.insertBefore(currentRow, document.querySelector('#scroll-area-buffer'));
            }
            currentRow = createNewCurrentRow();
            currentRowCommitted = false
        } else if (currentRow) {
            currentRowCommitted = false;
        }
        const img = document.createElement('img');
        img.src = imgUrlObjects[i].url;
        img.className = 'thumbnail-img col-md-4 img-fluid col'; // mb-2 rounded

        currentRow.appendChild(img);
    }
    if (currentRow) {
        document.getElementById('container')?.insertBefore(currentRow, document.querySelector('#scroll-area-buffer'));
    }
}

export const transformImageHeights = function() {
    return;
    document.querySelectorAll('.thumbnail-row')
        .forEach(thumbnailRow => {
            let elements = thumbnailRow.querySelectorAll('.thumbnail-img') as NodeList;
            let minHeight = Infinity;
            for (let j = 0; j < elements.length ; j ++) {
                let element = elements[j] as HTMLImageElement;
                if (element.height < minHeight) {
                    minHeight = element.height;
                }
            }
            minHeight = minHeight === Infinity ? 400 : minHeight;
            for (let j = 0; j < elements.length ; j ++) {
                let element = elements[j] as HTMLImageElement;
                const fraction = minHeight / element.height;
                element.style.height = `${minHeight}px`;
                element.style.width = `${element.width * fraction}px`;
            }
        });
}

export const transformImageWidths = function() {
    return;
    document.querySelectorAll('.thumbnail-row')
        .forEach(thumbnailRow => {
            let elements = thumbnailRow.querySelectorAll('.thumbnail-img') as NodeList;
            let totalWidth = 0;

            for (let j = 0; j < elements.length ; j ++) {
                let element = elements[j] as HTMLImageElement;
                totalWidth += element.width;
            }

            const containerWidth = 1200;
            const difference = containerWidth - totalWidth;
            const factor = difference / 3;
            if (difference === 0) {
                return;
            }
            for (let j = 0; j < elements.length ; j ++) {
                let element = elements[j] as HTMLImageElement;
                const fraction = factor / (element.width + factor);
                element.style.height = `${element.height + (fraction * element.height)}px`;
                element.style.width = `${element.width + factor}px`;
            }

            totalWidth = 0;
            for (let j = 0; j < elements.length ; j ++) {
                let element = elements[j] as HTMLImageElement;
                totalWidth += element.width;
            }
        });
}