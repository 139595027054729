export type ImageUrlData = {
    url: string,
    next: ImageUrl | null,
    prev: ImageUrl | null,
    rightArrowDefined?: boolean,
}

export class ImageUrl {
    url: string;
    next: ImageUrl | null;
    prev: ImageUrl | null;
    rightArrowDefined: boolean;

    constructor(data: ImageUrlData) {
        this.url = data.url;
        this.next = data.next;
        this.prev = data.prev;
        this.rightArrowDefined = false;
    }
}

export const urlsToImageUrls = function(imgUrls: string[]) {
    return imgUrls.map(url =>
        new ImageUrl({
            url: url,
            next: null,
            prev: null
        }));
}