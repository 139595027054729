import React from "react";
import './Grid.css'
import {Footer} from "./Footer";

export function AboutModal() {
    return (
        <div id="aboutModal" className="container">
            <br/>
            <h2 className="heading2">Who am I?</h2>
            <br/>
            <p>
                Welcome to Emerald Caress!
                <br/>
            </p>
            <p className="about-text">
                I'm Rahul. I like playing music on my keyboard, cooking, and programming. At some point,
                a few years ago, I accidentally discovered a desire to shoot pictures. My first year or
                so with the camera on my phone was mediocre at best; I shot
                out-of-focus pictures, paid no heed to lighting, did not appreciate depth-of-field and
                did not care to compose. I took pictures anyway because it made me happy.
                <br/>
            </p>
            <p className="about-text">
                As with all things born of repetition and persistence, success eventually and suddenly
                emerged! I soon bought a top-of-the-line camera, great lenses, gimbals, tripods and drones,
                travelled to places and began seeing portraits and cinematic compositions where seemingly
                none existed.
                <br/>
            </p>
            <p className="about-text">
                My goal with this website is not only to share my joy of clicking photos with you,
                but more importantly, also to inspire you to become a photographer. There is a
                tremendous happiness that a camera can give, and I want to share this revelation <b><i>with
                you</i></b>. 😊. If you have time for just one hobby, might I suggest that it be photography?
                <br/>
            </p>

            <p>
                Yours truly, <br/>
                Rahul Rajaram.
            </p>

            <br/>
            <br/>

            <Footer/>
        </div>
    )
}